.topRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 24px;
    margin-bottom: 4px;
}

.container {
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 800px;
    margin: auto;
}
