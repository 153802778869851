.grid {
    display: grid;
    grid-template-columns: 140px 1fr;
    gap: 10px 10px;
    /* align-items: center; */
}

.description {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
}

.toggleContainer {
    margin-left: 6px;
}
