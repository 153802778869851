.logo {
  display: flex;

  * {
    width: 100%;
    align-self: center;
  }
}

.text {
  display: flex;

  * {
    width: 100%;
    align-self: center;
  }
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button {
  align-self: stretch;
  cursor: pointer;
}

.note {
  margin: 0;
  font-size: 14px;
}