.container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.card {
    max-width: 100%;
    width: 480px;
    background-color: var(--normal-color);
    color: var(--normal-color-text);
    border-radius: 4px;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--light-brand-color-25);
    color: var(--light-brand-color-25-text);
    padding: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
}

.subtitle {
    font-size: 14px;
    margin-right: 36.6%;
}

.banner {
    max-height: 50px;
}

.overlayIconContainer {
    position: absolute;
    width: 72px;
    height: 72px;
    border-radius: 36px;
    padding: 16px;
    background-color: var(--background-darker-color);
    bottom: -48px;
    left: 24px;
}

.overlayIcon {
    height: 100%;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.middle {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}
