.dialogShim {
    top: 8%;
    margin-bottom: 8%;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--normal-color);
    box-shadow: 0 0 21px #0A0A0A4C;
    border-radius: 4px;
    padding: 7px 20px 20px 20px;
    min-width: 240px;
    overflow: auto;
    max-height: 84%;
}

.dialogShim.centered {
    position: absolute;
}

.dialogShim.positioned {
    position: fixed;
}

.close {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4px;
    right: 4px;
    cursor: pointer;
}

.close:hover {
    background-color: var(--background-darker-color);
}

.title {
    text-align: center;
    font-size: 24px;
    color: #50586A;
    margin: 0 60px;
}

.dialogShimClickaway {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.no-padding-shim {
    padding: 0px;
    max-width: 900px;
}
