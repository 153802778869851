.container,
.containerFullScreen {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.overflowContainer,
.overflowContainerFullScreen {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
}

.contentContainer,
.contentContainerFullScreen {
    flex: 1;
    max-width: 1224px;
    align-self: center;
    width: 100%;
    padding: 0 20px;
}

.contentContainerNotScrollable{
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.contentContainerFullScreen {
    padding: 0 20px;
}
