@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.topRightMaxContainer {
  position: absolute;
  right: 48px;
  top: 4px;
  padding: 2px;
  border-radius: 12px;
  background: #ffffff;
  width: min-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

@include media-breakpoint-up(sm) {
  .topRightMaxContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.rowWrapper {
  display: inline-flex;
  gap: 8px;
  flex: 0 0 auto;
}

@include media-breakpoint-up(sm) {
  .rowWrapper {
    display: contents;
    gap: 0;
  }
}

.pill {
  border: 1px solid var(--normal-border-color);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;
  background-color: var(--normal-color);
  opacity: 0.5;
  padding: 4px 8px;
  min-width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.selected {
  border-color: #a1a1a1;
  opacity: 1;
}

.color {
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000000;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.print {
  height: 100% !important;
}

.chartContainer {
  flex: 1 1 0;
  min-height: 0;
  overflow: hidden;
}

.zonesContainer {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
}

.seriesSelector {
  display: inline-flex;
  gap: 8px;
  padding: 8px;
  flex-wrap: wrap;
}

.seriesButton {
  border: 1px solid var(--normal-border-color);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;
  background-color: var(--normal-color);
  opacity: 0.5;
  padding: 4px 8px;
  min-width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.selected {
  border-color: #a1a1a1;
  opacity: 1;
}