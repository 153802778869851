.container {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
}

.indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 21px;
    height: 21px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
    background-color: var(--ext-brand-color);
    color: var(--ext-brand-color-text);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilePicture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.menu {
    cursor: pointer;
}
