.fullScreenButton {
  position: fixed;
  right: 26px;
  bottom: 10px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--background-highlight-color);
  border-radius: 4px;
  z-index: 10000;
}

.fullScreenButton:hover {
  background-color: var(--background-darker-color);
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.content {
  flex: 1;
  max-width: 1224px;
  align-self: center;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.topRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  margin-bottom: 4px;
}