@use "colors" as colors;

:root {
  --background-color: #f8f8fb;
  --background-highlight-color: #fff;
  --background-darker-color: #eff2f7;

  --normal-color: #fff;
  --normal-color-text: rgb(73, 80, 87);
  --normal-color-inactive-text: rgba(73, 80, 87, 0.65);
  --normal-border-color: #ced4da;
  --light-brand-color: #{colors.$primary};
  --light-brand-color-text: #fff;
  --light-brand-color-25: #{rgba(colors.$primary, 0.25)};
  --light-brand-color-25-text: #{colors.$primary};
  --light-brand-color-75: #{rgba(colors.$primary, 0.75)};
  --dark-brand-color: rgb(25, 49, 83);
  --dark-brand-color-25: rgba(25, 49, 83, 0.25);
  --dark-brand-color-text: #fff;
  --dark-brand-color-inactive-text: rgba(255, 255, 255, 0.65);
  --ext-brand-color: #e83c4d;
  --ext-brand-color-75: rgba(232, 60, 78, 0.75);
  --ext-brand-color-text: #fff;

  --normal-error-color: #ff6666;

  --nav-background-color: var(--dark-brand-color);
  --nav-active-color: var(--normal-color);
  --nav-inactive-color: var(--dark-brand-color-inactive-text);
}