.container {
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: 72px;
    cursor: pointer;
}

.container.selected {
    background-color: var(--dark-brand-color);
    color: var(--dark-brand-color-text);
}

.icon {
    object-fit: contain;
    width: 48px;
    height: 48px;
}

.col {
    display: flex;
    flex-direction: column;
}

.value {
    font-size: 20px;
    font-weight: 500;
}

.text {
    font-size: 13px;
    font-weight: 500;
    color: var(--normal-color-inactive-text);
}

.selected .text {
    color: var(--dark-brand-color-inactive-text);
}

.smallValue {
    font-size: 13px;
}
