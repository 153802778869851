.listBox {
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
}

.athlete {
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.athlete:hover {
    background-color: var(--background-darker-color);
    cursor: pointer;
}

.profilePic {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.name {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-brand-color);
}

.club {
    color: var(--dark-brand-color);
}

.trainingCountContainer {
    display: flex;
    align-items: center;
    justify-self: center;
}

.trainingCountText {
    color: var(--dark-brand-color-25);
}

.trainingCount {
    margin-left: 10px;
    font-size: 24px;
    color: var(--dark-brand-color);
}

.newRequestRow {
    display: flex;
    justify-content: flex-end;
}

.deleteIcon {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    border-radius: 4px;
    padding: 4px;
}

.deleteIcon:hover {
    background-color: var(--background-highlight-color);
    cursor: pointer;
}
