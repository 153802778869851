.chart {
  position: relative;
}

.avgMinMaxContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.avgMinMax {
  margin-bottom: 2px;
  padding: 2px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #6c757d;
  text-align: right;
  font-size: 14px;
}

.button {
   background-color: #ffffff !important;
   &:hover {
     background-color: var(--bs-btn-color) !important;
   }
 }