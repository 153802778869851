.input {
    width: 360px;
    height: 200px;
    background-color: var(--normal-color);
    border: 1px solid var(--normal-border-color);
    border-radius: 4px;
    line-height: 1.5;
    font-size: 13px;
    padding: 8px 12px;
    margin-top: 10px;
}

.button {
    margin-top: 10px;
}