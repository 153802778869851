.header {
    display: flex;
    justify-content: space-between;
}

.nameText {
    font-size: 24px;
}

.headerRight {
    flex-grow: 0;
    display: grid;
    grid-template-columns: auto auto;
}

.headerLabel {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-right: 4px;
}

.noDataContainer {
    background-color: var(--background-highlight-color);
    height: 400px;
    display: flex;
    font-size: 18px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
}

.granularitySelector {
    position: absolute;
    top: 0px;
    right: 8px;
    background-color: var(--background-color);
}
