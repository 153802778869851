.container {
  display: flex;
}

.container.highlighted {
  background-color: var(--background-highlight-color);
  border-radius: 4px;
}

.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeSelectorButton {
  cursor: pointer;
  padding: 6px 12px 8px 12px;
}

.timeSelectorButton:hover {
  background-color: var(--background-darker-color);
}

.calendarGrid {
  display: grid;
  grid-template-columns: repeat(7, 36px);
}

.calendar {
  width: unset !important;
}

.cell {
  height: 36px;
  width: 36px;
  padding-bottom: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentCell:hover {
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--background-color);
}

.selectedDate {
  border-radius: 4px;
  background-color: var(--background-darker-color);
}

.formControlBootstrap {
  padding: 0 !important;
}

.yearSelector {
  max-height: 200px;
  overflow-y: auto;
}

.currentDate {
  font-weight: 700;
}

.disabled {
  opacity: 0.6;
}

.disabled2 {
  background-color: var(--bs-secondary-bg) !important;
}