.container {
    background-color: var(--background-highlight-color);
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.container:focus {
    outline: none;
}
