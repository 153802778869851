.container {
  display: flex;
  background-color: white;
}

.zonesContainer {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
}


@media (max-width: 800px) {
  .container {
    display: block;
  }

  .zonesContainer {
    display: none;
  }
}

.zoomButton {
  width: 36px;
  height: 36px;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;

  &:hover {
    background-color: var(--bs-btn-color) !important;
  }
}


.zoomButton100 {
  height: 36px;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;

  &:hover {
    background-color: var(--bs-btn-color) !important;
  }
}

.fullscreenButton {
  width: 36px;
  height: 36px;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;

  &:hover {
    background-color: var(--bs-btn-color) !important;
  }
}

.topRight {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: end;
  align-items: end;
}

.buttons {
  display: flex;
  gap: 10px;
}

.avgMax {
  padding: 2px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #6c757d;
  text-align: right;
  font-size: 14px;
}