@import '../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';

.container {
  background-color: var(--dark-brand-color);
}

.liveText {
  position: absolute;
  top: 40px;
  left: 50px;
  color: var(--ext-brand-color);
  font-style: italic;
  font-weight: 700;
}

.logo {
  height: 38px;
}

.menuItems {
  display: flex;

  & > * {
    min-height: 48px;
  }
}

.nav {
  margin-right: auto;
}

.closeButton {
  color: white;
}

.close {
  position: absolute;
  right: 1em;
  top: 1em;
}

.openCollapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark-brand-color) !important;

  & > * {
    margin-bottom: 10px;
  }

  ul > li {
    margin-bottom: 10px;
  }
}

@media (max-width: 1199px) {
  .nav {
    margin-right: 0;
  }
}

.defaultNavbar {
  display: none;
}

@include media-breakpoint-up(xl) {
  .defaultNavbar {
    display: contents;
  }
}