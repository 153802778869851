.headerContainer {
  margin: 0 20px;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  width: 100%;
  max-width: 1224px;
}

.header > * {
  flex: 1;
}

.headerRight {
  flex-grow: 0;
  display: grid;
  grid-template-columns: auto auto;
}

.headerLabel {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-right: 4px;
}

.selectorWrapper {
  display: flex;
  align-items: center;
}

.nameText {
  font-size: 24px;
}

.filter {
  display: flex;
}

.trainingRow {
  display: contents;
}

.trainingCellHeader {
  padding: 4px 8px;
  font-weight: 600;
}

.scrollParent {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 0 20px;
}

.grid {
  max-width: 1224px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(9, max-content) 1fr;
  grid-auto-rows: max-content;
  gap: 0 4px;
}