@import '../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';

.container {
  flex: 1;
  position: relative;
}

.nestedContainer {
  padding-right: 10px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.name {
  padding: 6px;
  font-size: 16px;
  font-weight: 700;
}

.loading {
  flex: 1;
  display: flex;
  justify-content: center;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  margin-right: 1em;

  & > :not(.label) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@include media-breakpoint-up(sm) {
  .formGroup {
    align-items: center;
    flex-direction: row;
  }
}

.color {
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  border-radius: 8px;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.athlete {
  display: flex;
  flex-direction: row !important;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 10px;
  user-select: none;
  padding: 6px;
  align-items: center;
  min-height: 64px;

  &:hover .header {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.4);
  }
}

.switch {
  margin-left: 1em;
  margin-right: 1em;

  input {
    transform: scale(1.8);
  }
}

.label {
  margin-right: 0.5em;
}

.colorPickerFloating {
  padding: 10px;
  width: 288px;
}

.colorInnerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  & > * {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    border: var(--bs-border-width) solid var(--bs-border-color);
  }
}

.loading {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50px;
}

.noAthletes {
  flex: 1;
  display: flex;
  justify-content: center;
}