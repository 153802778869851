.listBox {
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
}

.coach {
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.deleteIcon {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    border-radius: 4px;
    padding: 4px;
}

.deleteIcon:hover {
    background-color: var(--background-darker-color);
    cursor: pointer;
}

.name {
    align-self: center;
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-brand-color);
}
