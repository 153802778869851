
.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.samples {
  overflow-y: auto;
  max-height: 300px;
  position: relative;

  table {
    width: 100%;

    th {
      position: sticky;
      top: 0;
      background-color: #ffffff;
    }

    td, th {
      font-weight: normal;
      padding: 2px 4px;
      vertical-align: top;
    }

    td.deleteSample {
      padding-top: 6px;
    }

    .firstCell {
      display: flex;
    }
  }
}

.delete {
  cursor: pointer;
  color: var(--ext-brand-color-75);
}

.lineChart {
  position: relative;
}

.chartButtonContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.selectionIndicator {
  display: inline-block;
  height: 30px;
  margin-top: 4px;
  margin-right: 4px;
  border-left: 4px solid var(--light-brand-color);
}