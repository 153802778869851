@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.container {
  background-color: var(--dark-brand-color);
  z-index: 10;
}

.logo {
  height: 38px;
}

.menuItems {
  display: flex;

  & > * {
    min-height: 48px;
  }
}

.switchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.switchButton {
  padding: 0 16px;
  min-height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ffffff;

  &:hover {
    background-color: #ffffff11
  }
}

.active {
  background-color: var(--light-brand-color);
  color: var(--light-brand-color-text);

  &:hover {
    background-color: var(--light-brand-color-75);
  }
}

.nav {
  margin-right: auto;
}

@media (max-width: 1199px) {
  .nav {
    margin-right: 0;
  }
}

.openCollapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark-brand-color) !important;

  & > * {
    margin-bottom: 10px;
  }

  ul > li {
    margin-bottom: 10px;
  }
}

.close {
  position: absolute;
  right: 1em;
  top: 1em;
}

.canvasBody {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.defaultNavbar {
  display: none;
}

@include media-breakpoint-up(xl) {
  .defaultNavbar {
    display: contents;
  }

  .switchContainer {
    margin-bottom: 0;
  }
}