.lineChart {
  position: relative;
}

.avgMinMaxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.avgMinMax {
  margin-bottom: 2px;
  padding: 2px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #6c757d;
  text-align: right;
  font-size: 14px;
}

.topRight {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.fullscreenButton {
  width: 36px;
  height: 36px;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;

  &:hover {
    background-color: var(--bs-btn-color) !important;
  }
}