.fullScreenButton {
    position: fixed;
    right: 26px;
    bottom: 10px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    z-index: 800;
}

.fullScreenButton:hover {
    background-color: var(--background-darker-color);
}
