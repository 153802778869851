.container {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.disabled {
    cursor: initial;
}

.bar {
    position: relative;
    width: 20px;
    height: 6px;
    padding-left: 8px;
    background-color: var(--light-brand-color-25);
    border-radius: 4px;
    margin-left: 8px;
    margin-right: 16px;
}

.bar.disabled {
    background-color: #d2d2d2;
}

.handle {
    position: absolute;
    top: -5px;
    left: -6px;
    height: 16px;
    width: 16px;
    background-color: var(--dark-brand-color);
    border-radius: 8px;
    transition: 0.3s;
    flex: 0;
}

.disabled {
    cursor: initial;
}

.active {
    left: 12px;
    background-color: var(--light-brand-color);
}

.handle.disabled {
    background-color: #d2d2d2;
}

.label {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 2px;
}
