.hrZones {
  display: flex;
}

.zonesArea{
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.zoneArea,
.zoneDetailsArea {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.outliner {
  border: 2px solid var(--background-darker-color);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  height: 40px;
  width: 20px;
  margin-left: 8px;
}

.numbersContainer {
  height: 40px;
  display: flex;
  margin-bottom: 6px;
}

.zone {
  display: flex;
  align-items: center;
  height: 43px;
  margin-bottom: 4px;

  &:first-child {
    margin-top: 20px;
  }
}

.maxLabel{
  margin-top: -4px;
}

.bpmLabel,
.percentageLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--background-darker-color);
  height: 36px;
  width: 80px;
  border-radius: 4px;
  margin: 2px 0;
}

.percentageLabel {
  width: 60px;
  margin-left: 10px;
}