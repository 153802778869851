@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.card {
  position: relative;
}

.panelTitle {
  margin-bottom: 10px;
}

.header {
  display: flex;
}

.topRightActions {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: flex-start;
}

.topRightActionsButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@include media-breakpoint-up(sm) {
  .topRightActionsButtons {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.closeContainer {
  margin-left: 20px;
}

.glanceContainer {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 8px;
}

@include media-breakpoint-up(sm) {
  .glanceContainer {
    grid-gap: 0;
  }
}


.map {
  flex: 1;
}

@media (max-width: 800px) {
  .panelOptions {
    display: block;
  }
}

.athleteSelector {
  //position: sticky;
  //top: 14px;
  display: inline-flex;
  gap: 8px;
  padding: 8px;
  margin-left: -8px;
  margin-bottom: -8px;
  border-radius: 12px;
  //z-index: 20;
  background: #ffffff;
  flex-wrap: wrap;
  //align-self: flex-start;
}

.selectionIcon {
  width: 26px;
  margin-right: 10px;
}

.leftPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.leftPanelShort {
  min-height: auto;
}

.infoBox {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.leftPanelSelection {
  display: flex;
  align-items: center;
}