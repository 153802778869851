.buttonRow {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.w120 {
    min-width: 120px;
    min-width: 120px;
}
