.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.interval {
    display: flex;
    align-items: center;
}

.timeSelectorButton,
.selector {
    cursor: pointer;
    border-radius: 4px;
    min-width: 34px;
    min-height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeSelectorButton:hover,
.selector:hover {
    background-color: var(--background-darker-color);
}

.dropdown {
    max-height: 200px;
    overflow-y: auto;
}