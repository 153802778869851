body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--normal-color-text);
    background-color: var(--background-color);
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.u-cursor-x, .u-cursor-y, .u-cursor-pt {
    z-index: 0 !important;
}

.gm-style iframe + div { border:none!important; } /* Hide Google Maps border on focus. */

@media print {
    html, body {
        height: initial;
    }
}

.toggleButton {
    opacity: 0.5;
}

.selected {
    opacity: 1;
}