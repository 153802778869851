@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.container {
  display: block;
  background-color: white;
}

.zonesContainer {
  width: 100%;
  margin-left: 0;
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chartWrapper {
  position: relative;
}

.chartInfo {
  position: absolute;
  left: 0;
  top: 0;
}

.chartInfoZones {
  position: absolute;
  left: 0;
  top: 0;
}

@include media-breakpoint-up(md) {
  .container {
    display: flex;
  }
  .zonesContainer {
    width: auto;
    margin-left: 10px;
  }


  .chartInfoZones {
    right: 0;
    left: auto;
  }
}