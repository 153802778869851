.coachingRequest {
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.profilePic {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.name {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-brand-color);
}

.email {
    color: var(--dark-brand-color);
}

.status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 0 8px 0 16px;
    color: var(--dark-brand-color-25);
}

.statusDenied {
    color: var(--ext-brand-color-75);
}

.dropdownText {
    font-weight: 400;
    color: var(--normal-color-text);
}

.dropdownTextDelete {
    font-weight: 400;
    color: var(--ext-brand-color-75);
}

.resendContainer {
    display: flex;
    align-items: center;
}

.deleteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    border-radius: 4px;
    padding: 4px;
}

.deleteIcon:hover {
    background-color: var(--background-darker-color);
    cursor: pointer;
}
