.button {
  background-color: transparent !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  & > * {
    height: 20px;
  }
}

.menu {
  min-width: 0 !important;
}

.item {
  & > * {
    height: 20px;
  }
}