@import '../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 1;
  }
}

.chartContainer {
  position: relative;
  display: flex;
  min-height: 0;
  min-width: 0;
  & > * {
    flex: 1;
  }
}

.zoomButtons {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 10px;
}

.zoomButton {
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  display: flex !important;
  align-items: center !important;
}

.zoomButton100 {
  background-color: #ffffff;
  display: flex !important;
  align-items: center !important;
}

.legend {
  flex: 0;
  display: flex;
  flex-direction: row;
  flex-basis: max-content;
  padding-bottom: 1em;
  gap: 0.5em;
  order: -1;
  align-self: flex-start;
}

.colorSwitch {
  display: flex;
  align-items: center;
}

.colorMarker {
  margin-right: 1em;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.athlete {
  border: 1px solid var(--normal-border-color);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;
  background-color: var(--normal-color);
  opacity: 0.5;
}

.selected {
  border-color: #a1a1a1;
  opacity: 1;
}

.athleteInner {
  margin: 4px 12px;
}

.color {
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

@include media-breakpoint-up(sm) {
  .container {
    flex-direction: row;
  }
  .legend {
    padding: 0 1em;
    order: 1;
    flex-direction: column;
  }
}