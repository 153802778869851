.container {
    display: grid;
    --grid-layout-gap: 16px;
    --grid-item--min-width: 220px;
    --grid-max-column-count: 3;
    /**
     * Calculated values.
     */
    --gap-count: calc(var(--grid-max-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-max-column-count));

    grid-template-columns: repeat(auto-fit, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    grid-gap: var(--grid-layout-gap);
}
