.select {
  max-width: 200px;
  margin-left: 20px;
}

.td {
  white-space: nowrap;
}

.printTitle {
  font-weight: bold;
}

.printButton {
  margin-left: 10px;
}

.printWrapper {
  overflow-x: visible !important;
  overflow-y: visible !important;
  width: fit-content !important;
  height: fit-content !important;
}

.tableResponsive {
  max-height: 530px;
  overflow: auto;

  tr:nth-child(even) {
    td, th {
      background-color: #fafafa !important;
    }
  }
}

@media print {
  .tableResponsive {
    overflow-x: visible !important;
    overflow-y: visible !important;
    width: fit-content;
    height: fit-content;
    font-size: 13px;
    max-height: initial;

    thead th {
      overflow-wrap: break-word;
      word-break: break-all;
    }

    td {
      padding: 0.1rem 0.3rem 0.1rem 0.3rem !important;
      vertical-align: middle;
    }
  }
}
