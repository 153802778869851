.dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.container {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: stretch;
}

.label {
    flex: 1;
}
