.row {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    grid-template-columns: max-content minmax(min-content, 180px);
    gap: 8px 40px;
}

.buttonRow {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}
