@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.buttons {
  display: flex;
  gap: 8px;
}

.downloadButton {
  align-self: center;
  margin-right: 0;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.dash {
  margin: 0 8px;
}

.headerCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
}

.notes {
  flex: 1;
  min-width: 300px;
  align-self: stretch;
}

.trainingName {
  font-size: 20px;
  font-weight: bold;
}

.sportIcon {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.subTitle {
  font-size: 18px;
}

.notesRow {
  display: flex;
  align-items: center;
}

.flex1 {
  flex: 1;
}

.saveNoteContainer {
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 38px;
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(md) {
  .buttons {
    display: contents;
  }
  .header {
    flex-direction: row;
    gap: 8px;
  }
  .notes {
    margin: 0 16px;
    align-self: center;
  }
}